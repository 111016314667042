import React, { useEffect, useState } from 'react';
import { Routes, Route, useLocation, useParams } from 'react-router-dom';
import { getTokens } from 'libs/helper/localStorageHelper';
import { Gnb } from 'features/commonFeatures/navigationBar/containers';
import mainRoutes from 'routes/mainRoutes';
import { observer } from 'mobx-react';
import styled, { css } from 'styled-components';
import { useStore } from 'stores/StoreHelper';

const LayoutWrapper = observer(() => {
  const [showAsideBar, setShowAsideBar] = useState<boolean>(true);

  return (
    <>
      {/* <Gnb /> */}
      <RouteContainerStyle>
        <Routes>
          {mainRoutes.map((route, index: number) => {
            return (
              <Route
                key={index}
                path={route.path}
                element={
                  <Auth isPrivate={route.authenticated}>
                    <route.element />
                  </Auth>
                }
              />
            );
          })}
        </Routes>
      </RouteContainerStyle>
    </>
  );
});

const Auth = ({ isPrivate, children }: { isPrivate?: boolean; children: JSX.Element }) => {
  const token = getTokens().accessToken !== null;

  if (isPrivate !== false) {
    if (token) {
      return children;
    } else {
      return children;
      // return <Navigate to="/login" />;
    }
  } else {
    return children;
  }
};

const RouteContainerStyle = styled.div<{ pathname?: string }>`
  margin: 50px;
`;

export default LayoutWrapper;
