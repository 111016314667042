import React, { createContext, useContext } from 'react';
import { MobXProviderContext, ProviderProps, useObserver } from 'mobx-react';
import RootStore from 'stores/RootStore';

const CustomContext = createContext(RootStore);

// MobX와 Hook을 동시에 사용하기 위해서는 다음과 같은 Wrapper가 필요하다.
const useStore = () => useContext(CustomContext);

// MobX와 Hook을 class 컴포넌트의 inject처럼 사용하기 위해서는 다음과 같은 Wrapper가 필요하다.
const useInject = (selector: any, baseComponent: any) => {
  const component = (ownProps: any) => {
    const store = useStore();

    /* eslint react-hooks/rules-of-hooks: "off" */
    return useObserver(() => baseComponent(selector(store, ownProps)));
  };

  component.displayName = baseComponent.name;

  return component;
};

export const CustomProvider = (props: ProviderProps) => {
  const { children, ...stores } = props;
  const parentValue = React.useContext(CustomContext);
  const mutableProviderRef = React.useRef({ ...parentValue, ...stores });
  const value = mutableProviderRef.current;

  return <MobXProviderContext.Provider value={value}>{children}</MobXProviderContext.Provider>;
};

export { useStore, useInject };
