/* eslint-disable no-plusplus */
import React, { useEffect, useState } from 'react';

import { observer } from 'mobx-react';

import styled from 'styled-components';
import WaferMap from 'features/wafer/components/Wafermap';

const MainPage = observer(() => {
  return (
    <>
      {/* <IFrameStyle src="/wafer" title="test" />
      <IFrameStyle src="/wafer" title="test" />
      <IFrameStyle src="/wafer" title="test" />
      <IFrameStyle src="/wafer" title="test" /> */}
      <WaferMap />
    </>
  );
});

const IFrameStyle = styled.iframe`
  width: 100%;
  height: 200px;
  border: 0;
`;
const WaferMapContainerStyle = styled.div`
  border: 2px solid #efefef;
  border-radius: 10px;
`;
export default MainPage;
