const REST_API_URL = process.env.REACT_APP_TEST_REST_API_URL;
const GOOGLE_MAP_API_KEY = process.env.REACT_APP_GOOGLE_MAP_API_KEY;
const KAKAO_CLIENT_SECRET_KEY = process.env.REACT_APP_TEST_KAKAO_CLIENT_SECRET_KEY;
const ROOT_HOST = process.env.REACT_APP_HOST;
const TOSSPAY_KEY = process.env.REACT_APP_TOSS_API_KEY;
const KAKAO_REST_API_KEY = process.env.REACT_APP_TEST_KAKAO_REST_API_KEY;

export {
  ROOT_HOST,
  REST_API_URL,
  KAKAO_CLIENT_SECRET_KEY,
  KAKAO_REST_API_KEY,
  GOOGLE_MAP_API_KEY,
  TOSSPAY_KEY,
};
