/* eslint-disable max-classes-per-file */
/* eslint-disable no-unused-expressions */
import { makeAutoObservable } from 'mobx';
import { ConfirmOptions, DrawerOptions, ProgressOptions } from 'types/CommonTypes';

/**
 * @description 지역관련 스토어
 */
class UIStore {
  reload = false;

  options: any = undefined;

  confirm: Confirm = new Confirm();

  drawer: Drawer = new Drawer();

  progress: Progress = new Progress();

  constructor() {
    makeAutoObservable(this);
  }

  /**
   * @description 주로 FlatList의 초기화를 위해 사용됨.
   * @param value
   */
  setReload(value: boolean, from?: number) {
    this.reload = value;
  }

  setPortalComponent(options: any) {
    console.log('aa', options.component);
    this.options = options;
    // this.portalShow = true;
  }
}

class Confirm {
  /**
   * @Confirm
   */
  confirmShow = false;

  options: ConfirmOptions | undefined;

  constructor() {
    makeAutoObservable(this);
  }

  show(options: ConfirmOptions) {
    this.options = options;
    this.confirmShow = true;
  }

  close() {
    if (typeof this.options?.onCancel !== 'undefined') {
      this.options?.onCancel();
    }
    this.confirmShow = false;
    // 모달이 사라지기전에 데이터가 사라져 전환이 어색해지므로 setTimeout으로 호출
    setTimeout(() => {
      this.options = undefined;
    }, 500);
  }

  confirm() {
    this.confirmShow = false;
    // 모달이 사라지기전에 데이터가 사라져 전환이 어색해지므로 setTimeout으로 호출
    setTimeout(() => {
      if (typeof this.options?.onConfirmed !== 'undefined') {
        this.options?.onConfirmed();
      }
      this.options = undefined;
    }, 50);
  }
}

class Drawer {
  /**
   * @Confirm
   */
  drawerShow = false;

  options: DrawerOptions | undefined;

  constructor() {
    makeAutoObservable(this);
  }

  show(options: DrawerOptions) {
    this.options = options;
    this.drawerShow = true;
  }

  close() {
    if (typeof this.options?.onCancel !== 'undefined') {
      this.options?.onCancel();
    }
    this.drawerShow = false;
    // 모달이 사라지기전에 데이터가 사라져 전환이 어색해지므로 setTimeout으로 호출
    setTimeout(() => {
      this.options = undefined;
    }, 500);
  }

  confirm() {
    this.drawerShow = false;
    // 모달이 사라지기전에 데이터가 사라져 전환이 어색해지므로 setTimeout으로 호출
    setTimeout(() => {
      if (typeof this.options?.onConfirmed !== 'undefined') {
        this.options?.onConfirmed();
      }
      this.options = undefined;
    }, 50);
  }
}

class Progress {
  /**
   * @Confirm
   */
  progressShow = false;

  options: ProgressOptions | undefined;

  constructor() {
    makeAutoObservable(this);
  }

  show(options?: ProgressOptions) {
    this.options = options;
    this.progressShow = true;
  }

  close() {
    // if (typeof this.options?.onCancel !== 'undefined') {
    //   this.options?.onCancel();
    // }
    this.progressShow = false;
    this.options = undefined;
  }
}

export default UIStore;
