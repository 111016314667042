import React, { useRef } from 'react';
import { useStore } from 'stores/StoreHelper';
import { observer } from 'mobx-react';
import useScreenType from 'libs/hooks/useScreenType';
import styled from 'styled-components';
// import CloseIcon from '@mui/icons-material/Close';

const Drawer = observer(() => {
  const { uiStore } = useStore();
  const defautWidth = useRef<number>(600);
  const screenType = useScreenType();

  const handleClickClose = async () => {
    uiStore.drawer.close();
  };

  // const handleClickConfirm = async () => {
  //   uiStore.drawer.confirm();
  // };

  return (
    <>
      {/* {uiStore.drawer.drawerShow && ( */}
      <DrawerContainerStyle
        width={
          screenType === 'window'
            ? `${uiStore.drawer.options?.width || 550}px`
            : '100%' || defautWidth.current
        }
        on={uiStore.drawer.drawerShow}
      >
        <CloseButtonStyle onClick={handleClickClose}>
          <img src="/images/close.png" style={{ width: 26, height: 26 }} />
        </CloseButtonStyle>
        <TitleStyle isMobile={screenType === 'mobile'}>{uiStore.drawer.options?.title}</TitleStyle>
        {typeof uiStore.drawer.options?.description !== 'undefined' && (
          <DescriptionStyle>{uiStore.drawer.options?.description}</DescriptionStyle>
        )}
        <ChildrenContainerStyle style={{ padding: screenType === 'window' ? '0 30px' : '0 15px' }}>
          {uiStore.drawer.options?.children}
        </ChildrenContainerStyle>
        {/* TODO: Mobile인지 판단하여 "Preview"버튼을 상시 표시할것 */}
      </DrawerContainerStyle>
    </>
  );
});

const DrawerContainerStyle = styled.div<{ width: any; on: boolean }>`
  position: fixed;
  right: ${(props) => (props.on ? '0px' : `-${props.width}`)};
  opacity: ${(props) => (props.on ? '1' : '0')};
  opacity: 1;
  top: 0;
  width: ${(props) => `${props.width}`};
  bottom: 0;
  z-index: 2;
  background: #fff;
  overflow-y: auto;
  transition: all 0.5s ease-in-out;
  /* 테스트s */
  /* background: black; */
  /* 테스트s */
`;

const CloseButtonStyle = styled.div`
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 40px;
  opacity: 0.6;
  cursor: pointer;
  transition: all 0.2s ease-out;
  position: sticky;
  top: 15px;
  z-index: 10001;
  &:hover {
    opacity: 1;
    transition: all 0.3s ease-out;
  }
`;

const TitleStyle = styled.div<{ isMobile: boolean }>`
  font-family: 'Raleway' !important;
  font-weight: 800;
  font-size: 40px;
  position: sticky;
  background: #fff;
  top: 0px;
  padding: ${(props) => (props.isMobile ? '20px 0' : '20px 0')};
  padding-left: ${(props) => (props.isMobile ? '10px' : '30px')};
  z-index: 10000;
  /* 테스트s */
  /* background: black;
  color: white; */
  /* 테스트s*/
`;

const ChildrenContainerStyle = styled.div``;

const DescriptionStyle = styled.div`
  font-weight: 400;
  font-size: 16px;
  padding: 20px 0 20px 0;
`;

export default Drawer;
