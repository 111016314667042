import AuthStore from 'stores/AuthStore';
import UIStore from './UIStore';
import WaferStore from './WaferStore';

const RootStore = {
  authStore: new AuthStore(),
  uiStore: new UIStore(),
  waferStore: new WaferStore(),
};

export default RootStore;
