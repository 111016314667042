import React, { useEffect, useRef, useState } from 'react';
import { Application, Graphics } from 'pixi.js';
import C2 from 'data/C2.json';
import { Row } from 'components/commons/layouts';
import '@pixi/extract';

const WaferDetail = () => {
  const appRef = useRef(null);

  const locationRef = useRef(null);

  const c2 = Object.entries(C2)[0][1].coordinations.map((coor) =>
    coor.loc.split(',').map((c) => Number(c)),
  );

  const drawChild = React.useCallback((app) => {
    const width = 7.5;
    const height = 3;

    c2.map((test) => {
      const g = new Graphics();
      g.interactive = true;
      g.beginFill(Colors[test[2]], 1);
      g.lineStyle(1, 0x0000ff, 1);
      g.drawRect(test[0] * width, test[1] * height, width, height);
      g.on('mouseover', (e) => {
        locationRef.current.innerHTML = `x:${test[0]}, y:${test[1]}`;
      });

      app.stage.addChild(g);
    });

    app.renderer.extract.canvas(app.stage).toBlob((b) => {
      const url = URL.createObjectURL(b);
    });
  }, []);

  useEffect(() => {
    // On first render create our application
    const app = new Application({
      width: 800,
      height: 800,
      backgroundColor: 0xffffff,
    });

    // Add app to DOM
    appRef.current.appendChild(app.view);
    // Start the PixiJS app
    app.start();
    drawChild(app);

    return () => {
      // On unload completely destroy the application and all of it's children
      app.destroy(true, true);
    };
  }, []);
  // const drawRect = () => {};
  return (
    <>
      <Row style={{ justifyContent: 'flex-start' }}>
        <div style={{ fontSize: 20, fontWeight: 600 }}>Wafer Info</div>
        <div ref={locationRef} />
      </Row>
      <div ref={appRef} style={{ display: 'flex', flexWrap: 'wrap' }} />;
    </>
  );
};

export const Colors = [
  0x9b59b6, 0x3ca03c, 0x9de4ff, 0x52e252, 0xa7eeff, 0xacf3ff, 0xffb6c1, 0xb4fbff, 0xff1493,
  0xffdc3c, 0xffa374, 0xcd0000, 0xcd853f, 0xf389ef, 0x828282,
];

export default WaferDetail;
