import _ from 'lodash';
import moment from 'moment';
import { InputFormat, ValidationType } from 'types/CommonTypes';

type ValidationTargetType = {
  type: 'required' | 'url' | 'email' | 'tel' | 'number';
  value: string;
};
const expressions = {
  text: /[^(가-힣ㄱ-ㅎㅏ-ㅣa-zA-Z0-9)]/,
  // email: /[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}/,
  email: /^[a-zA-Z0-9]+(?:[_.-][a-zA-Z0-9]+)*@[a-zA-Z0-9]+(?:[_.-][a-zA-Z0-9]+)*\.[a-zA-Z]{2,4}$/,
  tel: /^(?:\d{2}-\d{4}-\d{4}|\d{3}-\d{4}-\d{4}|\d{2}-\d{3}-\d{4}|\d{9,11})$/,
  // tel: /\d{2,3}-\d{3,4}-\d{4}/,
  number: /^[0-9\b]+$/,
  // url: /(http(s)?:\/\/)([a-z0-9\w]+\.*)+[a-z0-9]{2,4}/gi,
  // url: /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/gm,
  url: /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/gm,
  roman: /^[a-zA-Z0-9!@#$\-_]+$/,
  password: /^[A-Za-z0-9 !"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]+$/,
  youtube:
    /(?:https?:\/\/)?(?:www\.)?youtube\.com\/watch\?v=([a-zA-Z0-9_-]+)|(?:https?:\/\/)?youtu\.be\/([a-zA-Z0-9_-]+)/,
  timeRange: /^([01]?[0-9]|2[0-3]):?[0-5][0-9]$/,
};

function validityCheck(value: string, format: InputFormat) {
  /**
   * null일경우 유효성 통과이므로 true를 반환한다.
   */
  return value.match((expressions as any)[format]) !== null;
}

function validityCustomCheck(value: string, validation: ValidationType) {
  /**
   * null일경우 유효성 통과이므로 true를 반환한다.
   */
  const result = value.match(validation.expression) !== null;
  return result;
}

/**
 * @description 필수값 체크
 * @param value
 * @returns boolean : true:정상, false:실패
 */
const required = (value?: string | number) => {
  if (typeof value === 'undefined' || value === null) {
    return false;
  }
  if (typeof value !== 'number') {
    return value?.trim()?.length !== 0;
  } else {
    return value?.toString().length !== 0;
  }
};

/**
 * @description 날자값 체크
 * @param value
 * @returns boolean : true:정상, false:실패
 */
const date = (value?: string) => {
  if (typeof value === 'undefined' || value === null) {
    return false;
  }
  const dateTime = moment(value);
  return dateTime.isValid();
};

/**
 * @description 유효시간 체크
 * @param value
 * @returns boolean : true:정상, false:실패
 */
const timeRange = (value?: string) => {
  if (typeof value === 'undefined' || value === null) {
    return false;
  }
  return value?.match(expressions.timeRange) !== null;
};

/** @description 공백 불가 */
const url = (value?: string) => {
  if (typeof value === 'undefined' || value === null) {
    return false;
  }
  return value?.match(expressions.url) !== null;
};

/** @description 공백 허용 */
const urlOrEmpty = (value?: string) => {
  if (typeof value === 'undefined' || value?.length === 0 || value === null) {
    return true;
  }
  return value?.match(expressions.url) !== null;
};

const alphanumericWithSymbols = (value?: string) => {
  if (typeof value === 'undefined' || value === null) {
    return false;
  }
  return value?.match(expressions.roman) !== null;
};

const email = (value?: string) => {
  if (typeof value === 'undefined' || value === null) {
    return false;
  }
  return value?.match(expressions.email) !== null;
};

const password = (value?: string) => {
  if (typeof value === 'undefined') {
    return false;
  }
  return value?.match(expressions.password) !== null;
};

const tel = (value?: string) => {
  if (typeof value === 'undefined' || value === null) {
    return false;
  }
  const result = value?.match(expressions.tel) !== null;
  return result;
};

const number = (value?: string) => {
  if (typeof value === 'undefined' || value === null) {
    return false;
  }
  const result = value?.match(expressions.number) !== null;
  return result;
};

const youtube = (value?: string) => {
  if (typeof value === 'undefined' || value === null) {
    return false;
  }
  const result = value?.match(expressions.youtube) !== null;
  return result;
};

const custom = (value: string, format: RegExp | RegExp[]) => {
  if (typeof value === 'undefined' || value === null) {
    return false;
  }

  // format이 배열일 경우
  if (Array.isArray(format)) {
    for (const regex of format) {
      if (value.match(regex)) {
        return true;
      }
    }
    return false;
  }

  // format이 단일 RegExp일 경우
  return value.match(format) !== null;
};

const validate = (values: Array<ValidationTargetType>) => {
  const t = _.groupBy(values, 'type');
  let result = false;
  if (typeof t.required !== 'undefined') {
    result = !(
      t.required.filter((target: ValidationTargetType) => target?.value?.length === 0).length > 0
    );
  }

  if (typeof t.url !== 'undefined') {
    result =
      t.url.filter(
        (target: ValidationTargetType) =>
          target?.value?.match((expressions as any)[target?.type]) !== null,
      )?.length > 0;

    // result = value.match((expressions as any)[format]) === null ? false : true;
  }
  return result;
};

const validationHelper = {
  required,
  url,
  urlOrEmpty,
  validate,
  tel,
  email,
  password,
  number,
  alphanumericWithSymbols,
  youtube,
  custom,
  date,
  timeRange,
};

export { validityCheck, validityCustomCheck, validationHelper, ValidationTargetType };
