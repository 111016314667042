import { setTokens } from 'libs/helper/localStorageHelper';
import { makeAutoObservable } from 'mobx';
import { toast } from 'react-toastify';
import { AuthService } from 'services/AuthService';
import { LoginDto, SignUpDto } from 'services/data-contracts';
import { HttpClient } from 'services/http-client';

// import _ from "lodash";
import { REST_API_URL } from 'stores/AppStore';

export interface ResponseGenerator {
  config?: any;
  data?: any;
  headers?: any;
  request?: any;
  status?: number;
  error?: any;
  statusText?: string;
}

export interface isValid {
  isValid: boolean;
}

class AuthStore {
  constructor() {
    makeAutoObservable(this);
  }

  api: AuthService<unknown> = new AuthService(new HttpClient());

  users: any;

  status: number;

  userNameIsValid: isValid;

  emailIsValid: isValid;

  authError: any;

  clearStorage() {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('me');
    localStorage.removeItem('refreshToken');
  }
  /** ============================================================================================== */

  *login(loginInfo: LoginDto) {
    const { response } = yield this.api.signIn(loginInfo);
    if (response.status < 300) {
      setTokens({
        accessToken: response.data.accessToken,
        refreshToken: response.data.refreshToken,
      });
      return response.data;
    } else {
      toast(response.data.errorMessage, { type: 'warning' });
      return null;
    }
  }

  *signup(signupInfo: SignUpDto) {
    const { response } = yield this.api.signUpWithEmail(signupInfo);
    if (response.status < 300) {
      return response.data;
    } else {
      toast(response.data.errorMessage, { type: 'warning' });
      return null;
    }
  }

  logout = () => {};
}

export default AuthStore;
