import React, { useEffect, useRef, useState } from 'react';
import { Application, Graphics } from 'pixi.js';
import { Button, Slider, CircularProgress } from '@mui/material';
import { useStore } from 'stores/StoreHelper';
import LinearProgress, { LinearProgressProps } from '@mui/material/LinearProgress';
import styled from 'styled-components';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import axios from 'axios';
import WaferWrapper from './WaferWrapper';

import '@pixi/extract';

const WaferMap = () => {
  const COUNT = 100;
  const count = new Array(COUNT);
  let cassetCount = 5;

  const { waferStore } = useStore();
  const imageRef = useRef([]);
  // const containerRef = useRef(null);
  // const containerRef1 = useRef(null);
  // const containerRef2 = useRef(null);
  // const containerRef3 = useRef(null);
  // const containerRef4 = useRef(null);
  // const containerRef5 = useRef(null);

  const progressRef = useRef(null);

  const appRef = useRef(null);
  const [itemCount, setItemCount] = useState();
  const [busy, setBusy] = useState(true);
  const [progress, setProgress] = React.useState(0);

  function LinearProgressWithLabel(props) {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ width: '100%', mr: 1 }}>
          <LinearProgress variant="determinate" {...props} />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" color="text.secondary">{`${Math.round(
            // eslint-disable-next-line react/destructuring-assignment, react/prop-types
            props.value,
          )}%`}</Typography>
        </Box>
      </Box>
    );
  }

  let index = 0;
  const drawMap = React.useCallback((app) => {
    const g = new Graphics();
    axios.get('/data/C2.json').then((response) => {
      const t = response.data;
      Object.entries(t).forEach((element, index) => {
        setTimeout(() => {
          progressRef.current.innerText = (index / Object.entries(t).length) * 100;
        }, 0);
        const c2 = element[1].coordinations.map((coor) =>
          coor.loc.split(',').map((c) => Number(c)),
        );

        g.clear();

        const width = 7.5;
        const height = 3;

        c2.map((test) => {
          g.beginFill(Colors[test[2]], 1);
          g.drawRect(test[0] * width, test[1] * height, width, height);
          g.lineStyle(1, 0x0000ff, 1);
        });

        app.stage.addChild(g);

        app.renderer.extract.canvas(app.stage).toBlob((b) => {
          const url = URL.createObjectURL(b);
          const waferInfo = { lotId: element[1].lotid, number: element[1].number, url };
          waferStore.appendWafer(waferInfo);
          imageRef.current[index].setWafer({
            lotId: element[1].lotid,
            number: element[1].number,
            url,
          });
          index += 1;
        });
      });
      cassetCount += 1;

      if (cassetCount === 4) {
        setBusy(false);
      }
    });
  }, []);

  useEffect(() => {
    for (let index = 0; index < count.length; index += 1) {
      count[index] = index;
    }

    setItemCount(count);

    if (waferStore.wafers.length > 0) {
      setTimeout(() => {
        for (let index = 0; index < waferStore.wafers.length; index += 1) {
          imageRef.current[index].setWafer(waferStore.wafers[index]);
        }
      }, 100);
    }
  }, []);

  const handleZoomIn = () => {
    for (let index = 0; index < COUNT; index += 1) {
      imageRef.current[index].zoomIn(0.1);
    }
  };

  const handleZoomOut = () => {
    for (let index = 0; index < COUNT; index += 1) {
      imageRef.current[index].zoomOut(-0.1);
    }
  };

  const handleSliderChange = (e, value) => {
    for (let index = 0; index < COUNT; index += 1) {
      imageRef.current[index].changeConatinerSize(value);
    }
  };

  useEffect(() => {
    const app = new Application({
      width: 0,
      height: 0,
      backgroundColor: 0xffffff,
    });

    // Start the PixiJS app
    app.start();
    drawMap(app);

    return () => {
      app.destroy(true, true);
    };
  }, []);

  return (
    <>
      <div style={{ position: 'fixed', zIndex: -1, width: 0, height: 0 }}>
        <div ref={appRef} />
      </div>
      <CommanderStyle>
        <Button onClick={handleZoomIn}>Zoom In</Button>
        <Button onClick={handleZoomOut}>Zoom OUT</Button>
        <div style={{ width: 200, marginLeft: 20 }}>
          <Slider onChange={handleSliderChange} max={500} min={100} />
        </div>
      </CommanderStyle>
      <ContainerStyle>
        {itemCount?.map((c, index) => (
          // eslint-disable-next-line no-return-assign
          <WaferWrapper ref={(el) => (imageRef.current[index] = el)} />
        ))}
      </ContainerStyle>
      <ProgressStyle>
        <div ref={progressRef} />
        {/* <LinearProgressWithLabel value={progress} /> */}
      </ProgressStyle>

      {/* {busy && (
        <CircularStyle>
          <CircularProgress />
        </CircularStyle>
      )} */}
    </>
  );
};

export const Colors = [
  0x9b59b6, 0x3ca03c, 0x9de4ff, 0x52e252, 0xa7eeff, 0xacf3ff, 0xffb6c1, 0xb4fbff, 0xff1493,
  0xffdc3c, 0xffa374, 0xcd0000, 0xcd853f, 0xf389ef, 0x828282,
];

const CommanderStyle = styled.div`
  position: sticky;
  top: 10px;
  z-index: 10;
  padding-left: 20px;
  display: flex;
  justify-content: flex-start;
  height: 60px;
  align-items: center;
  background: #fff;
  border: 2px solid #818181;
  border-radius: 15px;
  margin-bottom: 20px;
`;

const ContainerStyle = styled.div`
  border: 1px solid #efefef;
  border-radius: 15px;
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  position: relative;

  background: #fff;
`;

const ProgressStyle = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CircularStyle = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export default WaferMap;
