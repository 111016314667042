import { DefaultTheme } from 'styled-components';

/** media에 사용되는 고정 사이즈 */
const size = {
  mobile: '1000px',
  desk: '1001px',
};

export const theme: DefaultTheme = {
  colors: {
    pointColor: '#3E64EA',
    defaultTextColor: '#333',
    borderColor: '#ddd',
  },
  defaultFont: {
    color: '#333',
    fontSize: 14,
  },

  /**
   *  미디어 쿼리 사용방식
   *  @media ${(props) => props.theme.media.mobile} {
  }
   */
  media: {
    desk: `(min-width: ${size.desk})`,
    mobile: `(max-width: ${size.mobile})`,
  },
};

export default theme;
