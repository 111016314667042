import React, { useEffect, useState, useCallback, useLayoutEffect } from 'react';

import { validationHelper as VH } from 'libs/helper/validationHelper';
import {
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  FormHelperText,
  Button,
} from '@mui/material';

import { debounce } from 'lodash';
import { useStore } from 'stores/StoreHelper';
import { LoginDto, SignUpDto, UserRegistrationResponse } from 'services/data-contracts';

import CheckIcon from '@mui/icons-material/Check';
import DoNotDisturbAltIcon from '@mui/icons-material/DoNotDisturbAlt';
import PasswordForm from 'components/commons/PasswordForm';
import { useTranslation } from 'react-i18next';
import TitleForm from 'components/commons/TitleForm';
import { useModelManagement } from 'libs/hooks/useModelUpdate';
import useInitializeModel from 'libs/hooks/useInitializeModel';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled from 'styled-components';

interface SignUpModel extends SignUpDto {
  termsValid?: boolean;
}

const LoginPage = () => {
  const defaultProps: Partial<LoginDto> = {};
  const { t } = useTranslation();
  const { updateServiceModel, initialBinding, serviceModel } = useModelManagement<LoginDto>();
  const [qrCode, setQrCode] = useState<string>('');
  const navigate = useNavigate();
  const [localModel, setLocalModel] = useInitializeModel<LoginDto>(
    undefined,
    defaultProps,
    initialBinding,
  );

  const [isValid, setIsValid] = useState<boolean>(false);

  const { authStore, uiStore } = useStore();

  const handleClickSignup = () => {
    navigate('/signup');
  };

  const handleClickSignUp = async () => {
    if (serviceModel) {
      const result = await authStore.login(serviceModel);
      if (result !== null) {
        navigate('/');
      }
    }
  };

  return (
    <CenterStyle>
      <div style={{ margin: '30px 0', fontSize: 20, fontWeight: 600 }}>Sign-up</div>
      {!qrCode ? (
        <>
          <div>
            <FormControl sx={{ m: 1, minWidth: 300, width: 400, margin: 1 }}>
              <TitleStyle>USER EMAIL</TitleStyle>
              <TitleForm
                placeHolder="사용자 email을 작성해 주세요"
                required
                onChangeValue={(e: string) => {
                  updateServiceModel('username', e);
                  // handleChangeEmail(e.target.value);
                }}
              />
            </FormControl>
          </div>
          <div>
            <FormControl sx={{ m: 1, minWidth: 300, width: 400, margin: 1 }}>
              <TitleStyle>PASSWORD</TitleStyle>
              <PasswordForm
                onChangeValue={(value: string) => {
                  updateServiceModel('password', value);
                }}
              />
            </FormControl>
          </div>
          <div>
            <FormControl sx={{ m: 1, minWidth: 300, width: 400, margin: 1 }}>
              <TitleStyle>OTP</TitleStyle>
              <TitleForm
                onChangeValue={(value: string) => {
                  updateServiceModel('otpCode', value);
                }}
              />
            </FormControl>
          </div>

          <div style={{ marginTop: 30 }}>
            <Button
              style={{ minWidth: 300, width: 400, height: 50 }}
              onClick={handleClickSignUp}
              variant="contained"
              fullWidth
            >
              LOGIN
            </Button>
          </div>
        </>
      ) : (
        <>
          <div style={{ padding: '20px 0' }}>
            Authenticator를 실행하신후 아래 QR코드를 스캔해 주세요.
          </div>
          <div>
            <img src={`data:image/png;base64,${qrCode}`} alt="Base64 Image" />
          </div>
        </>
      )}
      <div onClick={handleClickSignup} style={{ marginTop: 40, cursor: 'pointer' }}>
        SIGNUP
      </div>
    </CenterStyle>
  );
};

const CenterStyle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const TitleStyle = styled.div`
  font-size: 12px;
  margin-bottom: 5px;
  font-weight: 600;
`;

export default LoginPage;
