import { makeAutoObservable } from 'mobx';

import _ from 'lodash';
import { WaferInfo } from 'types/CommonTypes';

class WaferStore {
  constructor() {
    makeAutoObservable(this);
  }

  wafers: Array<WaferInfo> = [];

  setWafers(wafers: Array<WaferInfo>) {
    this.wafers = wafers;
  }

  appendWafer(wafer: WaferInfo) {
    this.wafers.push(wafer);
  }

  clearWafers() {
    this.wafers = [];
  }
}

export default WaferStore;
