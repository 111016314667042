import React, { useRef, useImperativeHandle, forwardRef, useEffect } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';

const WaferWrapper = forwardRef((props, ref) => {
  const navigate = useNavigate();
  const lotId = useRef(null);
  const number = useRef(null);
  const containerRef = useRef(null);
  const imageRef = useRef(null);
  const titleRef = useRef(null);
  const zoomRef = useRef(null);

  useImperativeHandle(ref, () => ({
    setWafer,
    zoomIn,
    zoomOut,
    changeConatinerSize,
  }));

  const setWafer = (props) => {
    lotId.current = props.lotId;
    number.current = props.number;
    imageRef.current.src = props.url;
    titleRef.current.innerHTML = `${props.lotId}-${props.number}`;
  };

  const zoomIn = (size) => {
    zoomRef.current.zoomIn(size, 0, 'easeOut');
  };

  const zoomOut = (size) => {
    zoomRef.current.zoomIn(size, 0, 'easeOut');
  };

  const changeConatinerSize = (size) => {
    containerRef.current.style.width = `${size}px`;
    containerRef.current.style.height = `${size + 30}px`;
  };

  const handleZoom = (e, s, u) => {
    console.log(e);
    console.log(s);
    console.log(u);
  };

  const handleClickGoDetail = () => {
    navigate(`/lots/${lotId.current}/wafers/${number.current}`);
  };
  return (
    <WaferWrapperStyle ref={containerRef}>
      <div
        ref={titleRef}
        style={{
          fontSize: 11,
          fontWeight: 500,
          marginBottom: 10,
          cursor: 'pointer',
          textAlign: 'center',
        }}
        onClick={handleClickGoDetail}
      />
      <TransformWrapper onZoom={handleZoom} ref={zoomRef}>
        <TransformComponent onZoom={handleZoom}>
          <img ref={imageRef} alt="" style={{ width: '100%', height: '100%' }} />
        </TransformComponent>
      </TransformWrapper>
    </WaferWrapperStyle>
  );
});

const WaferWrapperStyle = styled.div`
  width: 100px;
  height: 130px;
  overflow: hidden;
  margin: 2px;
`;

export default WaferWrapper;
