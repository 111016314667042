import AuthStore from './AuthStore';

import UIStore from './UIStore';
import WaferStore from './WaferStore';

class RootStore {
  authStore = new AuthStore();

  uiStore = new UIStore();

  waferStore = new WaferStore();
}
export default RootStore;
