import React from 'react';
import { useStore } from 'stores/StoreHelper';
import { observer } from 'mobx-react';
import useScreenType from 'libs/hooks/useScreenType';
import Theme from 'resources/styles/common/theme';
import styled from 'styled-components';
import Modal from 'react-modal';

const Confirm2 = observer(({}: any) => {
  const screenType = useScreenType();
  const { uiStore } = useStore();

  const handleClickClose = async () => {
    uiStore.confirm.close();
  };

  const handleClickConfirm = async () => {
    uiStore.confirm.confirm();
  };

  return (
    <Modal
      isOpen={uiStore.confirm.confirmShow}
      style={{
        overlay: { backgroundColor: 'rgba(0, 0, 0, 0.75)', zIndex: 100000000 },
        content: {
          margin: 'auto',
          height: `${screenType === 'window' ? '214px' : '200px'}`,
          width: `${screenType === 'window' ? '400px' : ''}`,
          border: '0',
          display: 'flex',
          flexDirection: 'column',
          padding: 0,
          borderRadius: 8,
        },
      }}
      ariaHideApp={false}
    >
      <ContentStyle>
        <TitleStyle isSingle={typeof uiStore.confirm.options?.title === 'undefined'}>
          {typeof uiStore.confirm.options?.title === 'undefined'
            ? 'Confirm'
            : uiStore.confirm.options?.title}
        </TitleStyle>
        {typeof uiStore.confirm.options?.message !== 'undefined' && (
          <SubtitleStyle>{uiStore.confirm.options?.message}</SubtitleStyle>
        )}
      </ContentStyle>
      <ButtonAreaStyle>
        <ButtonStyle backgroundColor={'#DDDDDD'} onClick={handleClickClose}>
          {uiStore.confirm.options?.closeText || 'close'}
        </ButtonStyle>
        <ButtonStyle
          backgroundColor={Theme.colors.pointColor}
          fontColor={Theme.colors.pointColor}
          onClick={handleClickConfirm}
        >
          {uiStore.confirm.options?.okText || 'confirm'}
        </ButtonStyle>
      </ButtonAreaStyle>
    </Modal>
  );
});

const ContentStyle = styled.div`
  height: 74px;
  padding: 20px 20px 20px 20px;
`;

const TitleStyle = styled.div<{ isSingle?: boolean }>`
  /* margin: 0 0 ${(props) => (props.isSingle ? 0 : 30)}px 0; */
  /* text-align: ${(props) => (props.isSingle ? 'center' : 'left')}; */

  @media ${(props) => props.theme.media.mobile} {
    font-size: 18px;
    text-align: center;
    margin: 20px 0 0 0;
  }
  @media ${(props) => props.theme.media.desk} {
    font-size: 18px;
    text-align: center;
    margin: 20px 0 0 0;
  }
`;

const SubtitleStyle = styled.div`
  font-size: 16px;
  margin: 20px 0 0 0;
  color: #666666;
  text-align: center;
`;

const ButtonAreaStyle = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  height: 50px;
  padding: 20px 20px 20px 20px;
`;

const ButtonStyle = styled.div<{ backgroundColor?: string; fontColor?: string }>`
  display: flex;
  cursor: pointer;
  border-radius: 8px;
  background-color: #f6f6f6;
  color: ${(props) => props.fontColor};
  align-items: center;
  justify-content: center;
  font-weight: 500;
  @media ${(props) => props.theme.media.mobile} {
    width: 120px;
    height: 50px;
  }
  @media ${(props) => props.theme.media.desk} {
    width: 175px;
    height: 60px;
  }
`;

export default Confirm2;
