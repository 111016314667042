import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import backend from 'i18next-http-backend';
import i18n from 'i18next';
import ko from './locales/ko.json';
import en from './locales/en.json';
import jp from './locales/jp.json';
import cn from './locales/cn.json';
import sp from './locales/sp.json';

const resources = {
  ko: { translation: ko },
  en: { translation: en },
  jp: { translation: jp },
  cn: { translation: cn },
  sp: { translation: sp },
};

const languageDetector = new LanguageDetector();
const options = {
  // order: ['cookie', 'localStorage', 'sessionStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],
  order: ['localStorage'],
  lookupLocalStorage: 'i18nextLng',
};

languageDetector.init(options);

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .use(backend)
  .init({
    resources,
    // lng: 'ko',
    detection: options,
    fallbackLng: ['ko', 'en', 'jp', 'cn', 'sp'], // detected lng이 불가능 할때 en을 사용하겠다.
    supportedLngs: ['ko', 'en', 'jp', 'cn', 'sp'],
    keySeparator: false, // 'messages.welcome' 와 같은 키 형식의 form을 사용할지 여부
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
