import React, { useEffect, useLayoutEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { ToastContainer } from 'react-toastify';
import { useStore } from 'stores/StoreHelper';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import Confirm2 from 'components/commons/Confirm2';
import theme from 'resources/styles/common/theme';
import Drawer from 'components/commons/Drawer';
import ScrollTop from 'libs/commons/ScrollTop';
import ErrorBoundary from 'ErrorBoundary';
import LayoutWrapper from 'LayoutWrapper';
import * as Pages from 'pages';
import 'react-toastify/dist/ReactToastify.css';
import 'resources/css/Common.css';
import 'resources/css/Links.css';
import 'resources/css/Core.css';
import 'resources/css/website.css';
import 'resources/css/mobile.css';

/** RN 관련 interface 등록 */
declare global {
  interface Window {
    ReactNativeWebView: any;
  }
}

const App = observer(() => {
  return (
    <ErrorBoundary>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <ScrollTop />
          <Routes>
            <Route path="/*" element={<LayoutWrapper />} />
            <Route path="*" element={Pages.NotFoundPage} />
          </Routes>
          <ToastContainer limit={1} pauseOnFocusLoss={false} />
          <Confirm2 />
          <Drawer />
        </BrowserRouter>
      </ThemeProvider>
    </ErrorBoundary>
  );
});

export default App;
