import React, {
  forwardRef,
  KeyboardEvent,
  useEffect,
  useRef,
  useState,
  useImperativeHandle,
} from 'react';
import { TextField, InputAdornment } from '@mui/material';
import { validationHelper } from 'libs/helper/validationHelper';
import { t } from 'i18next';
import { withStyles } from '@material-ui/core/styles';

const DefaultFormStyle = () => {
  return { width: '100%', margin: '0 10px', marginBottom: 1 };
};

const styles = (theme: any) => ({
  outlinedInput: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#EFEFEF',
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: '#23BA50',
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#23BA50',
    },
  },
});

const TitleForm = forwardRef(
  (
    {
      model,
      label,
      rows,
      initialValue,
      showLabel = true,
      multiline = false,
      useCount = false,
      required = true,
      validationType,
      surfix,
      helperText = '',
      maxLength,
      errorText = t('A146'),
      placeHolder,
      disabled,
      onChangeValue,
      onEntered,
      style,
      classes,
    }: {
      model?: string;
      label?: string;
      rows?: number;
      surfix?: string;
      useCount?: boolean;
      initialValue?: string;
      validationType?: 'number' | 'tel' | 'email';
      showLabel?: boolean;
      placeHolder?: string;
      multiline?: boolean;
      required?: boolean;
      maxLength?: number;
      helperText?: string;
      errorText?: string;
      disabled?: boolean;
      onEntered?: () => void;
      onChangeValue: (value: string | number) => void;
      style?: any;
      classes?: any;
    },
    ref: any,
  ) => {
    const titleRef = useRef<any>(null);

    const [isValid, setIsValid] = useState<boolean>(true);
    const [titleModel, setTitleModel] = useState<string>('');
    const [isDisabled, setIsDisabled] = useState<boolean>();
    const validationType2 = useRef<'number' | 'tel' | 'email'>();

    const sx = useRef<any>(null);

    useImperativeHandle(ref, () => ({
      validate,
      setValue,
      getValue,
    }));

    const setValue = (value: string) => {
      titleRef.current.value = value;
      setTitleModel(value.toString());
    };

    const getValue = () => {
      return titleRef.current.value;
    };

    const validate = (type: 'number' | 'tel' | 'email') => {
      validationType2.current = type;
      checkValidate(titleRef.current.defaultValue);
    };

    const checkValidate = (value: string) => {
      if (isDisabled) {
        return;
      }
      if (!required && typeof validationType2 === 'undefined') {
        return;
      }

      const result = validationHelper.required(value);
      let resultType = true;

      switch (validationType2.current) {
        case 'number':
          resultType = validationHelper.number(value);
          break;
        case 'tel':
          resultType = validationHelper.tel(value);
          break;
        case 'email':
          resultType = validationHelper.email(value);
          break;
        default:
          break;
      }

      setIsValid(result && resultType);
    };

    useEffect(() => {
      if (typeof initialValue !== 'undefined') {
        setTitleModel(initialValue);
        if (initialValue !== '') {
          checkValidate(initialValue);
        }
      }
    }, [initialValue]);

    useEffect(() => {
      if (validationType) {
        validationType2.current = validationType;
      }
    }, [validationType]);

    useEffect(() => {
      if (showLabel) {
        sx.current = DefaultFormStyle;
      } else {
        (sx.current = DefaultFormStyle),
          {
            '& legend': { display: 'none' },
            '& fieldset': { top: 0 },
            '& MuiFormHelperText-root': { display: 'none' },
          };
      }
      if (titleRef !== null) {
        // titleRef.current.focus();
      }
      return () => {
        setTitleModel('');
      };
    }, []);

    useEffect(() => {
      if (typeof disabled !== 'undefined') {
        setIsDisabled(disabled);
        setIsValid(true);
      }
    }, [disabled]);

    return (
      <>
        <TextField
          className={style?.color ? classes.outlinedInput : ''}
          required={required}
          inputRef={titleRef}
          autoComplete="off"
          error={!isValid}
          id={'temp'}
          // label={showLabel && (label || 'Link title')}
          maxRows={5}
          rows={rows}
          disabled={isDisabled}
          multiline={multiline}
          placeholder={placeHolder}
          InputLabelProps={{ shrink: false }}
          InputProps={{
            style: { color: style?.color !== undefined ? style?.color : '' },
            endAdornment: <InputAdornment position="end">{surfix && surfix}</InputAdornment>,
          }}
          inputProps={{
            maxLength,
            onBlur: (e: any) => {
              checkValidate(e.target.value);
            },
            onKeyDown: (e: KeyboardEvent<HTMLInputElement>) => {
              if (typeof onEntered === 'function') {
                if (e.key === 'Enter') {
                  onEntered();
                }
              }
            },
          }}
          onChange={(e: any) => {
            checkValidate(e.target.value);
            setTitleModel(e.target.value);
            onChangeValue(e.target.value);
          }}
          sx={{ width: style?.width ? style?.width : '100%' }}
          helperText={!isValid ? errorText : helperText}
          value={titleModel}
        />
        {useCount && (
          <div
            style={{ color: maxLength === titleModel?.length ? 'red' : '#000', textAlign: 'left' }}
          >
            {maxLength && maxLength} / {titleModel?.length}
          </div>
        )}
      </>
    );
  },
);

export default withStyles(styles)(TitleForm);
