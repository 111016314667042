import React, { forwardRef, useEffect, useRef, useState } from 'react';
import { validationHelper } from 'libs/helper/validationHelper';

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import {
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  FormHelperText,
} from '@mui/material';
import { t } from 'i18next';

const PasswordForm = forwardRef(
  (
    {
      model,
      label,
      rows,
      showLabel = true,
      multiline = false,
      required = true,
      helperText = '',
      errorText = t('A146'),
      placeHolder,
      onChangeValue,
      onEntered,
    }: {
      model?: string;
      label?: string;
      rows?: number;
      showLabel?: boolean;
      placeHolder?: string;
      multiline?: boolean;
      required?: boolean;
      helperText?: string;
      errorText?: string;
      onEntered?: () => void;
      onChangeValue: (value: string) => void;
    },
    ref: any,
  ) => {
    const titleRef = useRef<any>(null);

    const [isValid, setIsValid] = useState<boolean>(true);
    const [passwordModel, setPasswordModel] = useState<string>('');
    const [showPassword, setShowPassword] = useState(false);
    const [meetsPassword, setMeetsPassword] = useState<boolean>(false);

    const sx = useRef<any>(null);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const checkValidate = (value: string) => {
      if (!required) {
        return;
      }
      const result = validationHelper.required(value);
      setIsValid(result);
    };

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
    };

    useEffect(() => {
      if (typeof model !== 'undefined') {
        setPasswordModel(model);
        checkValidate(model);
      }
      return () => {
        setPasswordModel('');
      };
    }, [model]);

    useEffect(() => {
      if (meetsPassword === false) {
        onChangeValue(passwordModel);
      }
    }, [meetsPassword, passwordModel]);

    return (
      <>
        <OutlinedInput
          error={meetsPassword}
          sx={{ width: '100%' }}
          autoComplete="off"
          inputProps={{ minLength: 8, maxLength: 14 }}
          id="outlined-adornment-password"
          type={showPassword ? 'text' : 'password'}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
          onChange={(e: any) => {
            setPasswordModel(e.target.value);
            setMeetsPassword(e.target.value.length < 8);
          }}
        />
        {meetsPassword && <FormHelperText> {t('A105')}</FormHelperText>}
      </>
    );
  },
);

export default PasswordForm;
