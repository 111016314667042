import React, { useEffect, useState, useCallback, useLayoutEffect } from 'react';

import { validationHelper as VH } from 'libs/helper/validationHelper';
import {
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  FormHelperText,
  Button,
} from '@mui/material';

import { debounce } from 'lodash';
import { useStore } from 'stores/StoreHelper';
import { SignUpDto, UserRegistrationResponse } from 'services/data-contracts';

import CheckIcon from '@mui/icons-material/Check';
import DoNotDisturbAltIcon from '@mui/icons-material/DoNotDisturbAlt';
import PasswordForm from 'components/commons/PasswordForm';
import { useTranslation } from 'react-i18next';
import TitleForm from 'components/commons/TitleForm';
import { useModelManagement } from 'libs/hooks/useModelUpdate';
import useInitializeModel from 'libs/hooks/useInitializeModel';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled from 'styled-components';

interface SignUpModel extends SignUpDto {
  termsValid?: boolean;
}

const EmailSignUpForm = () => {
  const defaultProps: Partial<SignUpModel> = {};
  const { t } = useTranslation();
  const { updateServiceModel, initialBinding, serviceModel } = useModelManagement<SignUpModel>();
  const [qrCode, setQrCode] = useState<string>('');
  const navigate = useNavigate();
  const [localModel, setLocalModel] = useInitializeModel<SignUpModel>(
    undefined,
    defaultProps,
    initialBinding,
  );

  const [isValid, setIsValid] = useState<boolean>(false);

  const { authStore, uiStore } = useStore();

  const handleClickLogin = () => {
    navigate('/login');
  };
  const checkValidation = () => {};

  // const handleChangeEmail = useCallback(
  //   debounce(async (username) => {
  //     const emailResult = VH.email(username);
  //     if (emailResult) {
  //       setModel((prev) => {
  //         return { ...prev, username };
  //       });
  //     } else {
  //       setValidEmail(true);
  //       setEmailErrorMessage(t('A101'));
  //     }
  //     setModel((prev) => {
  //       return { ...prev, username };
  //     });
  //   }, 500),
  //   [],
  // );

  const handleClickSignUp = async () => {
    if (serviceModel) {
      const result = (await authStore.signup(serviceModel)) as unknown as UserRegistrationResponse;
      if (result.qrCodeBase64) {
        setQrCode(result.qrCodeBase64);
      }
    }
  };

  return (
    <CenterStyle>
      <div style={{ margin: '30px 0', fontSize: 20, fontWeight: 600 }}>Sign-up</div>
      {!qrCode ? (
        <>
          <div>
            <FormControl sx={{ m: 1, minWidth: 300, width: 400, margin: 1 }}>
              <TitleStyle>USER EMAIL</TitleStyle>
              <TitleForm
                placeHolder="사용자 email을 작성해 주세요"
                required
                onChangeValue={(e: string) => {
                  updateServiceModel('username', e);
                  // handleChangeEmail(e.target.value);
                }}
              />
            </FormControl>
          </div>
          <div>
            <FormControl sx={{ m: 1, minWidth: 300, width: 400, margin: 1 }}>
              <TitleStyle>PASSWORD</TitleStyle>
              <PasswordForm
                onChangeValue={(value: string) => {
                  updateServiceModel('password', value);
                }}
              />
            </FormControl>
          </div>
          <div>
            <FormControl sx={{ m: 1, minWidth: 300, width: 400, margin: 1 }}>
              <TitleStyle>USER NAME</TitleStyle>
              <TitleForm
                onChangeValue={(value: string) => {
                  updateServiceModel('profile', { ...serviceModel?.profile, name: value });
                }}
              />
            </FormControl>
          </div>
          <div>
            <FormControl sx={{ m: 1, minWidth: 300, width: 400, margin: 1 }}>
              <TitleStyle>PHONE NUMBER</TitleStyle>
              <TitleForm
                onChangeValue={(value: string) => {
                  updateServiceModel('profile', { ...serviceModel?.profile, phoneNumber: value });
                }}
              />
            </FormControl>
          </div>
          <div style={{ marginTop: 30 }}>
            <Button
              style={{ minWidth: 300, width: 400, height: 50 }}
              onClick={handleClickSignUp}
              variant="contained"
              fullWidth
            >
              Register
            </Button>
          </div>
        </>
      ) : (
        <>
          <div style={{ padding: '20px 0' }}>
            Authenticator를 실행하신후 아래 QR코드를 스캔해 주세요.
          </div>
          <div>
            <img src={`data:image/png;base64,${qrCode}`} alt="Base64 Image" />
          </div>
        </>
      )}
      <div onClick={handleClickLogin} style={{ marginTop: 40, cursor: 'pointer' }}>
        LOGIN
      </div>
    </CenterStyle>
  );
};

const CenterStyle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const TitleStyle = styled.div`
  font-size: 12px;
  margin-bottom: 5px;
  font-weight: 600;
`;

export default EmailSignUpForm;
