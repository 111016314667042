/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { LoginDto, SignUpDto, TokenDto, UserRegistrationResponse } from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class AuthService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags AuthService
   * @name SignUpWithEmail
   * @summary 회원가입
   * @request POST:/v1/auth/sign-up
   * @secure
   */
  signUpWithEmail = (data: SignUpDto, params: RequestParams = {}) =>
    this.http.request<UserRegistrationResponse, any>({
      path: `/v1/auth/sign-up`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags AuthService
   * @name SignIn
   * @summary 로그인
   * @request POST:/v1/auth/sign-in
   * @secure
   */
  signIn = (data: LoginDto, params: RequestParams = {}) =>
    this.http.request<TokenDto, any>({
      path: `/v1/auth/sign-in`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description 토큰 재발급
   *
   * @tags AuthService
   * @name RefreshToken
   * @summary 토큰 재발급
   * @request POST:/v1/auth/refresh-token
   * @secure
   */
  refreshToken = (data: TokenDto, params: RequestParams = {}) =>
    this.http.request<TokenDto, any>({
      path: `/v1/auth/refresh-token`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description 로그아웃
   *
   * @tags AuthService
   * @name Logout
   * @summary 로그아웃
   * @request POST:/v1/auth/logout
   * @secure
   */
  logout = (data: TokenDto, params: RequestParams = {}) =>
    this.http.request<void, any>({
      path: `/v1/auth/logout`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
}
