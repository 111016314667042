import React, { CSSProperties, ReactChild } from 'react';
import styled from 'styled-components';

const Row = ({
  style,
  children,
}: {
  style?: CSSProperties;
  children: JSX.Element | JSX.Element[];
}) => {
  const styles = { ...style };
  return <RowStyle style={styles}>{children}</RowStyle>;
};

const RowStyle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  > div {
    margin-right: 3px;
  }
  > div:last-child {
    margin-right: 0px;
  }
`;

export default Row;
