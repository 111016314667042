import { RoutePropInterface } from 'types/CommonTypes';
import * as Pages from 'pages';
import SignUpPage from 'pages/SignUpPage';

const mainRoutes: RoutePropInterface[] = [
  {
    path: '/',
    authenticated: false,
    title: 'livelink',
    element: Pages.HomePage,
  },

  {
    path: '/lots/:lotid/wafers/:number',
    title: '회사소개',
    element: Pages.WaferPage,
  },

  {
    path: '/login',
    title: '로그인',
    authenticated: false,
    element: Pages.LoginPage,
  },
  {
    path: '/signup',
    title: '회원가입',
    authenticated: false,
    element: SignUpPage,
  },

  {
    path: '/my',
    title: '마이페이지',
    element: Pages.MyPage,
  },
];

export default mainRoutes;
