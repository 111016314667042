import { useState, useRef, useEffect } from 'react';

type AdditionalProperties<T, Base> = Pick<T, Exclude<keyof T, keyof Base>>;

/**
 * @description 기존 모델에서 추가된 프로퍼티를 추출한다.
 * @param obj DTO에서 확장된 모델
 * @param dummyObject 오리지날 DTO
 * @returns
 */
export function extractAdditionalProperties<T, U>(
  obj: T,
  dummyObject: U,
): AdditionalProperties<T, U> {
  const result: Partial<T> = {};
  const baseKeys: string[] = Object.keys(dummyObject as unknown as object);
  for (const key in obj) {
    if (!baseKeys.includes(key as string)) {
      result[key as keyof T] = obj[key as keyof T];
    }
  }

  return result as AdditionalProperties<T, U>;
}

export const useModelManagement = <T>() => {
  const initialized = useRef<boolean>(false);
  /**
   * @description UI업데이트 및 공유를 위한 Model
   */
  const [stateModel, setStateModel] = useState<T>();
  const [initialModel, setInitialModel] = useState<T>();

  /**
   * @description API 처리를 위한 데이터 셋
   * @description 주로 Silent Update를 위해 사용한다.
   */
  const serviceModel = useRef<T>();

  /**
   * @description UI갱신이 필요한 경우만 호출하여 Rerender실행
   * */
  function setState<K extends keyof T>(key: K, value: T[K]): void {
    const t = stateModel;
    setStateModel((prev: T) => {
      return { ...prev, [key]: value };
    });
    updateServiceModel(key, value);
  }

  //update Ui muitiply
  function setMultipleState(updates: Partial<T>): void {
    setStateModel((prev: T | undefined) => {
      let result: T | undefined;

      if (!prev) {
        result = updates as T; // 만약 prev가 없으면 updates를 반환
      } else {
        result = { ...prev, ...updates };
      }
      // serviceModel.current를 갱신
      for (const key in result) {
        if (Object.prototype.hasOwnProperty.call(result, key)) {
          updateServiceModel(key as keyof T, result[key]);
        }
      }

      return result;
    });
  }

  /**
   * @description UI갱신없이 서비스 모델만 갱신.
   * @param key
   * @param value
   */
  function updateServiceModel<K extends keyof T>(key: K, value: T[K]): void {
    if (!serviceModel.current) {
      serviceModel.current = {} as T;
    }
    serviceModel.current[key] = value;
  }

  function updateServiceModelMultiple(updates: Partial<T>): void {
    if (!serviceModel.current) {
      serviceModel.current = {} as T;
    }

    for (const key in updates) {
      if (Object.prototype.hasOwnProperty.call(updates, key)) {
        const updateKey = key as keyof T;
        const updateValue = updates[updateKey];
        if (updateValue !== undefined) {
          serviceModel.current[updateKey] = updateValue as T[keyof T];
        } else {
          delete serviceModel.current[updateKey];
        }
      }
    }
  }

  /**
   * @description 컴포넌트의 초기값을 설정한다.
   * @param model
   */
  function initialBinding(model: T | undefined): void {
    if (model) {
      //가장 상위에 선언되어야 함.
      serviceModel.current = { ...model };
      setInitialModel({ ...model });
      setStateModel({ ...model });
      initialized.current = true;
    } else {
      setInitialModel(undefined);
      setStateModel(undefined);
      serviceModel.current = undefined;
      initialized.current = false;
    }
  }

  const unmountModel = () => {
    serviceModel.current = undefined;
    setInitialModel(undefined);
    setStateModel(undefined);
  };

  useEffect(() => {
    return () => {
      unmountModel();
    };
  }, []);

  return {
    initialized,
    setState,
    stateModel,
    setStateModel,
    setMultipleState,
    initialBinding,
    initialModel,
    serviceModel: serviceModel.current,
    updateServiceModel,
    updateServiceModelMultiple,
    extractAdditionalProperties,
    unmountModel,
  };
};
