import { useState, useEffect } from 'react';
import _ from 'lodash';

const useScreenType = () => {
  const [mode, setMode] = useState<'window' | 'mobile'>();

  function handleResize() {
    if (window.innerWidth <= 1000) {
      setMode('mobile');
    } else {
      setMode('window');
    }
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return mode;
};

export default useScreenType;
